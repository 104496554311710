import { useCallback } from "react";
import { Translations } from "../types/UIConfig";
import { useConfig } from "./useConfig";
import _ from "lodash";

/**
 * A React hook that provides translation functionality based on the UI configuration.
 *
 * @returns A translate function that accepts a getter function and optional default text override
 *
 * When no translation is found, it will fallback to using text from DEFAULT_TRANSLATIONS,
 * which will not be translated but will ensure there is always a value displayed.
 * An error will be logged to the console in this case.
 *
 * @example
 * // Using the hook in a component:
 * function MyComponent() {
 *   const translate = useTranslations();
 *
 *   // Get a translated string
 *   const buttonText = translate((t) => t.eligibility.stepOne.nextButton.label);
 *
 *   // Example with nested translations
 *   const headerText = translate((t) => t.eligibility.eligibilityModal.header);
 *
 *   // Example with default text override
 *   const customText = translate((t) => t.some.missing.text, "Fallback text");
 *
 *   return <button>{buttonText}</button>;
 * }
 *
 * // Example translations object structure:
 * // {
 * //   eligibility: {
 * //     stepOne: {
 * //       nextButton: {
 * //         label: "Next"
 * //       }
 * //     },
 * //     eligibilityModal: {
 * //       header: "Check eligibility"
 * //     }
 * //   }
 * // }
 */
export function useTranslations(): TranslateFn {
  const config = useConfig();
  const translate: TranslateFn = useCallback(
    (getter, defaultTextOverride) => {
      if (config === undefined) {
        const defaultText = defaultTextOverride ?? getter(DEFAULT_TRANSLATIONS);
        console.error(
          `Missing UIConfig for translation, using defaultText: ${defaultText}`
        );
        return defaultText;
      }

      if (config.translations === undefined) {
        const defaultText = defaultTextOverride ?? getter(DEFAULT_TRANSLATIONS);
        console.error(
          `Missing translations from UIConfig, using defaultText: ${defaultText}`
        );
        return defaultText;
      }

      const text = getter(config.translations);
      if (_.isEmpty(text)) {
        const defaultText = defaultTextOverride ?? getter(DEFAULT_TRANSLATIONS);
        console.error(
          `Missing translation text from UIConfig, using defaultText: ${defaultText}`
        );

        return defaultText;
      }

      return text;
    },
    [config]
  );

  return translate;
}

type TranslateFn = (
  getter: (t: Translations) => string,
  defaultTextOverride?: string
) => string;

const DEFAULT_TRANSLATIONS = {
  savedFirstSchool: {
    header: "Saved school!",
    body: "You saved your first school 🎉 Continue to save schools to create a list.",
    closeButton: { label: "Done" },
  },
  forms: {
    address: {
      addressLine1: {
        label: "Street address",
        helpText: "E.g.: 532 Oakland Ave",
      },
      addressLine2: {
        label: "Apartment, suite, or floor # (Optional)",
        helpText: "E.g.: 5B",
      },
      city: { label: "City", helpText: "E.g.: New Orleans" },
      state: { label: "State", helpText: "E.g.: LA" },
      zipCode: { label: "Zip code", helpText: "E.g.: 10181" },
    },
    dropdown: { helpText: "Select answer" },
  },
  eligibility: {
    learnMoreUrl: {
      label: "Learn more",
    },
    filter: {
      header: "Eligible schools",
      body: "Check eligibility with a student to see what they qualify for.",
    },
    notEligibleForAny: {
      header: "Not eligible for any school",
      body: "Review or modify your eligibility check, or contact the district for help.",
    },
    error: {
      header: "Unable to check eligibility",
      body: "The BPS system is working extra hard right now. Please try again later.",
    },
    alert: {
      avlBtn: { label: "Check eligibility" },
      label:
        "This school has eligibility requirements. {{avlBtn}} with a student to see if they qualify.",
    },
    nudge: {
      avlBtn: { label: "Check eligibility" },
      label:
        "Not sure what else to pick? {{avlBtn}} with a student to see what they qualify for.",
    },
    stepOne: {
      body: "To understand what your options are, share a bit more about what you’d like to do and when.",
      enrollmentPeriod: {
        label: "What enrollment period are you interested in?",
      },
      formTemplate: { label: "What would you like to do?" },
      nextButton: { label: "Next" },
      cancelButton: { label: "Cancel" },
    },
    stepTwo: {
      body: "To better understand what the student qualifies for, tell us a bit more about them and the household.",
      noEligibleCancelButton: { label: "Continue without eligibility" },
      noEligibleNextButton: { label: "Change eligibility" },
      nextButton: { label: "Next" },
      cancelButton: { label: "Cancel" },
    },
    eligibilityModal: {
      header: "Check eligibility",
      empty: { label: "N/A" },
      loadingMessage: { label: "Warming up the eligibility engines" },
    },
    welcomeModal: {
      header: "Welcome to Great Starts 🎉",
      body: "We're excited to help you explore Boston's child care and education options. Whether you're looking for child care, Boston Pre-K or BPS options, you're in the right place!\n\nChild care and high school are not restricted based on Boston address. BPS K-8 school lists are tied to your home address. To see your eligible schools make sure to check your eligibility.",
      acknowledgement: {
        label: "I understand. Do not show this again",
      },
      cancelButton: { label: "Continue without" },
      continueButton: { label: "Check eligibility" },
      changeEligibility: { label: "Change eligibility" },
    },
  },
} satisfies Translations;
