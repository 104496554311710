import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
} from "@chakra-ui/react";
import { Field, FieldProps, useField } from "formik";
import { ChangeEvent, useCallback } from "react";
import { useTranslations } from "../../hooks/useTranslations";

type SelectOption = {
  label: string;
  value: string;
  className?: string;
};

type Props = {
  name: string;
  label: string;
  options: SelectOption[];
  onChange?: (value: string) => void;
  isRequired?: boolean;
};

/**
 * Convenience wrapper around the Select input.
 * Use this in (Formik) forms.
 */
export const SingleSelect = ({
  name,
  label,
  options,
  onChange,
  isRequired,
}: Props) => {
  const [, , helper] = useField(name);
  const translate = useTranslations();

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      helper.setValue(event.target.value);
      onChange && onChange(event.target.value);
    },
    [helper, onChange]
  );

  return (
    <Field as="select" name={name} isRequired={isRequired}>
      {({ field, meta }: FieldProps) => (
        <FormControl
          isRequired={isRequired}
          isInvalid={Boolean(meta.error) && meta.touched}
        >
          <FormLabel>{label}</FormLabel>
          <Select
            {...field}
            onChange={handleOnChange}
            placeholder={translate((t) => t.forms.dropdown.helpText)}
          >
            {options.map((option) => (
              <option
                key={option.value}
                value={option.value}
                className={option.className}
              >
                {option.label}
              </option>
            ))}
          </Select>
          {meta.error && <FormErrorMessage>{meta.error}</FormErrorMessage>}
        </FormControl>
      )}
    </Field>
  );
};
