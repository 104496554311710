import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { dataFileName } from "../services/lang";
import { Language } from "../types/Language";
import { Placement as PlacementType, Eligibility } from "../types/Placement";
import {
  EligibilityConfig,
  isEligibilityNotApplicable,
  PlacementMapping,
} from "../types/UIConfig";
import { fetchConfigHash } from "../services/fetchConfigHash";
import { EligibilitySchool } from "../services/eligibility";
// import { mockData } from "../mockData/boston";

const reduxSearchModule = require("redux-search");
const { createSearchAction } = reduxSearchModule;

export interface PlacementMap {
  [key: string]: PlacementType;
}

export type PlacementsSliceType =
  | { status: "loading" }
  | { status: "finished"; byId: PlacementMap; eligibilityEnabled: boolean };

const initialState: PlacementsSliceType = {
  status: "loading",
} as PlacementsSliceType;

interface FetchPlacementsOptions {
  placementMapping: PlacementMapping;
  lang: Language;
  organizationPath: string;
  eligibilityConfig: EligibilityConfig | undefined;
}

export const fetchPlacements = createAsyncThunk(
  "placements/fetch",
  async ({
    placementMapping,
    lang,
    organizationPath,
    eligibilityConfig,
  }: FetchPlacementsOptions): Promise<PlacementType[]> => {
    const URL = process.env.REACT_APP_BUCKET_URL;
    if (!URL || !organizationPath) return Promise.resolve([]);
    const etag = await fetchConfigHash(organizationPath, dataFileName(lang));
    const endpoint = `${URL}/output/${organizationPath}/${dataFileName(
      lang
    )}?etag=${etag}`;

    // return mockData.map<PlacementType>((datum) =>
    //   toInitialPlacement(datum, placementMapping, eligibilityConfig)
    // );

    return await axios.get(endpoint).then(({ data }) => {
      const placements = (data as unknown[]).map<PlacementType>((datum) =>
        toInitialPlacement(datum, placementMapping, eligibilityConfig)
      );
      return placements;
    });
  }
);

export function toInitialPlacement(
  datum: any,
  placementMapping: PlacementMapping,
  eligibilityConfig: EligibilityConfig | undefined
): PlacementType {
  const lat = Number(datum[placementMapping.latFieldName]);
  const lng = Number(datum[placementMapping.lngFieldName]);

  // Default eligibility status is "eligible"
  let eligibility: Eligibility = "eligible";

  // Check if placement eligibility is configured and is disabled for this placement
  if (
    eligibilityConfig?.placementEligibility?.fieldName &&
    isEligibilityNotApplicable(datum, eligibilityConfig)
  ) {
    // If disabled, set eligibility to "not-applicable"
    eligibility = "not-applicable";
  }

  return {
    id: datum[placementMapping.idFieldName],
    name: datum[placementMapping.nameFieldName],
    lat,
    lng,
    boundaries: placementMapping.boundaryFieldName
      ? getBoundaries(datum, placementMapping.boundaryFieldName)
      : undefined,
    details: {
      ...datum,
    },
    eligibility,
  };
}

function getBoundaries(
  datum: any,
  boundaries: { [boundaryType: string]: string }
): { [boundaryType: string]: string[] } {
  return Object.keys(boundaries).reduce((prev, key) => {
    return { ...prev, [key]: datum[boundaries[key]] };
  }, {});
}

const placementsSlice = createSlice({
  name: "placements",
  initialState,
  reducers: {
    setPlacementsEligibility: (
      state,
      action: PayloadAction<{ ineligibleSchools: EligibilitySchool[] }>
    ) => {
      if (state.status !== "finished") return;

      const ineligibleSchoolIds = new Set(
        action.payload.ineligibleSchools.map((school) => school.referenceId)
      );
      Object.keys(state.byId).forEach((id) => {
        const placement = state.byId[id];
        if (placement.eligibility === "not-applicable") {
          // eligibility is not applicable for this placement. Skip it.
          return;
        }

        placement.eligibility = !ineligibleSchoolIds.has(id)
          ? "eligible"
          : "not-eligible";
      });

      state.eligibilityEnabled = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPlacements.fulfilled, (state, action: any) => {
      const { payload } = action;
      return {
        status: "finished",
        byId: payload.reduce((acc: PlacementMap, cur: PlacementType) => {
          acc[cur.id] = cur;
          return acc;
        }, {}),
        eligibilityEnabled: false,
      };
    });
  },
});

export const searchPlacements = createSearchAction("placements");

const { reducer } = placementsSlice;
export const { setPlacementsEligibility } = placementsSlice.actions;

export default reducer;
