import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { setShowFirstSavedSchoolModal } from "../../store/modals";
import { EligibilityInfoAlert } from "../Feedback/EligibilityInfoAlert";
import { setSavedFirstSchoolAcknowledged } from "../../store/saved";
import { useConfig } from "../../hooks/useConfig";
import { useTranslations } from "../../hooks/useTranslations";

type SavedFirstSchoolDialogProps = {
  isOpen: boolean;
};
export const SavedFirstSchoolDialog = ({
  isOpen,
}: SavedFirstSchoolDialogProps) => {
  const dispatch = useDispatch();
  const config = useConfig();
  const translate = useTranslations();
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {}}
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent m={4}>
        <ModalHeader fontSize="lg" fontWeight="bold">
          {translate((t) => t.savedFirstSchool?.header)}
        </ModalHeader>
        <ModalBody as={Flex} direction="column" gap={4}>
          <Text whiteSpace="pre-wrap">
            {translate((t) => t.savedFirstSchool.body)}
          </Text>

          <EligibilityInfoAlert>
            {({ checkEligibilityLink }) =>
              checkEligibilityLink(config?.translations?.eligibility.nudge)
            }
          </EligibilityInfoAlert>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="ghost"
            color="primary.500"
            aria-label="done"
            onClick={() => {
              dispatch(setShowFirstSavedSchoolModal(false));
              dispatch(setSavedFirstSchoolAcknowledged(true));
            }}
            _focus={{ outline: "none", boxShadow: "none" }}
          >
            {translate((t) => t.savedFirstSchool?.closeButton.label)}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
