import axios from "axios";
import _ from "lodash";
import { loadPersistedEligibilityAnswers } from "../store/persistedEligibilityAnswers";
import { PlacementMap } from "../store/placements";
import { Address } from "./address";
import { z } from "zod";
import { FormikErrors } from "formik";

export const EnrollmentPeriodSchema = z.object({
  id: z.string(),
  name: z.string(),
});
export type EnrollmentPeriod = z.infer<typeof EnrollmentPeriodSchema>;
export const GetEnrollmentPeriodResponseSchema = z.object({
  enrollment_period: z.array(EnrollmentPeriodSchema),
});

export const FormTemplateSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
});
export type FormTemplate = z.infer<typeof FormTemplateSchema>;
export const GetFormTemplateResponseSchema = z.object({
  form_template: z.array(FormTemplateSchema),
});

export const FormQuestionOptionSchema = z.object({
  id: z.string(),
  label: z.string(),
});
export type FormQuestionOption = z.infer<typeof FormQuestionOptionSchema>;

export const EligibilityQuestionSchema = z.object({
  id: z.string(),
  category: z.string().optional(),
  question: z.string(),
  requirement: z.literal("Required").optional(),
  type: z.string(),
  options: z.array(FormQuestionOptionSchema).default([]), // TODO: switch to use discriminated union
});
export type EligibilityQuestion = z.infer<typeof EligibilityQuestionSchema>;

export type EligibilityForm = Record<string, string | Address>;

export const EligibilitySchoolSchema = z.object({
  id: z.string(),
  name: z.string(),
  referenceId: z.string(),
});
export type EligibilitySchool = z.infer<typeof EligibilitySchoolSchema>;

export const FindEligibilityResponseSchema = z.object({
  ineligibleSchools: z.array(EligibilitySchoolSchema),
});
export type FindEligibilityResponse = z.infer<
  typeof FindEligibilityResponseSchema
>;

export const fetchEnrollmentPeriodsByOrg = async (
  organizationPath: string,
): Promise<EnrollmentPeriod[]> => {
  const URL = process.env.REACT_APP_ELIGILITY_ENDPOINT;
  if (!URL) return Promise.reject();

  const path = `${URL}/organizations/${organizationPath}/enrollmentPeriods`;
  const response = await axios.get(path);
  return GetEnrollmentPeriodResponseSchema.parse(response.data)
    .enrollment_period;
};

export const fetchFormTemplatesByEnrollmentPeriod = async (
  organizationPath: string,
  enrollmentPeriodId: string,
): Promise<FormTemplate[]> => {
  const URL = process.env.REACT_APP_ELIGILITY_ENDPOINT;
  if (!URL) return Promise.reject();

  const path = `${URL}/organizations/${organizationPath}/enrollmentPeriods/${enrollmentPeriodId}/formTemplates`;
  const response = await axios.get(path);
  return GetFormTemplateResponseSchema.parse(response.data).form_template;
};

export const fetchEligibilityQuestions = async (
  organizationPath: string,
  formTemplateId: string,
  language: string,
): Promise<EligibilityQuestion[]> => {
  const URL = process.env.REACT_APP_ELIGILITY_ENDPOINT;
  if (!URL) return Promise.reject();

  const path = `${URL}/organizations/${organizationPath}/formTemplates/${formTemplateId}/stepPreRanking?applicationType=Explore&language=${language}`;
  const response = await axios.get(path);
  return z.array(EligibilityQuestionSchema).parse(response.data);
};

export const fetchEligibility = async (
  organizationPath: string,
  formTemplateId: string,
  questions: EligibilityForm,
): Promise<FindEligibilityResponse> => {
  const URL = process.env.REACT_APP_ELIGILITY_ENDPOINT;
  if (!URL) return Promise.reject();

  const path = `${URL}/organizations/${organizationPath}/formTemplates/${formTemplateId}/findEligibility`;
  const response = await axios.post(path, {
    questionIdToAnswer: questions,
    applicationType: "Explore",
  });
  return FindEligibilityResponseSchema.parse(response.data);
};

export const getInitialValues = (
  formTemplateId: string,
  questions: EligibilityQuestion[],
) => {
  const persistedAnswers = loadPersistedEligibilityAnswers(formTemplateId);
  const initialValues: EligibilityForm = {};
  questions.forEach((question) => {
    if (question.type === "Address") {
      return (initialValues[question.id] = {
        streetAddress: "",
        streetAddressLine2: "",
        city: "",
        state: "",
        zipCode: "",
      });
    }

    initialValues[question.id] = question.options[0].id;
  });

  return _.merge(initialValues, persistedAnswers);
};

export const calculateEligibleSchoolIds = (
  placements: PlacementMap,
  ineligibleSchools: EligibilitySchool[],
): string[] => {
  const ineligibleSchoolIds = new Set(
    ineligibleSchools.map((es) => es.referenceId),
  );
  const eligibleSchoolIds = Object.keys(placements).filter((id) => {
    return !ineligibleSchoolIds.has(id);
  });

  return eligibleSchoolIds;
};

export const validateEligibilityForm = (
  answers: EligibilityForm,
  questions: EligibilityQuestion[],
): FormikErrors<EligibilityForm> => {
  const validationErrors: FormikErrors<EligibilityForm> = {};
  questions.forEach((question) => {
    if (question.requirement !== "Required") {
      return;
    }

    const answer = answers[question.id];
    if (question.type === "Address" && typeof answer === "object") {
      const errors: any = {};
      if (_.isEmpty(answer.streetAddress)) {
        errors[`streetAddress`] = "This field is required";
      }
      if (_.isEmpty(answer.city)) {
        errors[`city`] = "This field is required";
      }
      if (_.isEmpty(answer.state)) {
        errors[`state`] = "This field is required";
      }
      if (_.isEmpty(answer.zipCode)) {
        errors[`zipCode`] = "This field is required";
      }
      if (_.isEmpty(errors)) {
        return;
      }

      validationErrors[question.id] = errors;
      return;
    }

    if (
      _.isEmpty(answer) &&
      typeof answer === "string" &&
      answer.trim() === ""
    ) {
      validationErrors[question.id] = "This field is required";
      return;
    }
  });

  return validationErrors;
};
