import { Flex, Image, Text } from "@chakra-ui/react";
import { useTranslations } from "../../hooks/useTranslations";
import noEligibleSchoolImage from "../../images/no-eligible-schools.svg";

export function NoEligibleSchools() {
  const translate = useTranslations();
  return (
    <Flex
      top="0"
      left="0"
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      direction="column"
      gap={2}
      mb={4}
    >
      <Image width={140} src={noEligibleSchoolImage} />
      <Text fontWeight="bold" whiteSpace="pre-wrap">
        {translate((t) => t.eligibility.notEligibleForAny.header)}
      </Text>
      <Text textAlign="center" whiteSpace="pre-wrap">
        {translate((t) => t.eligibility.notEligibleForAny.body)}
      </Text>
    </Flex>
  );
}
