import { Button, Flex, Link, ModalBody, Text } from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  EnrollmentPeriod,
  fetchEnrollmentPeriodsByOrg,
  fetchFormTemplatesByEnrollmentPeriod,
  FormTemplate,
} from "../../services/eligibility";
import { Form, Formik, FormikErrors } from "formik";
import { SingleSelect } from "../Fields/SingleSelect";
import { RadioGroup } from "../Fields/RadioGroup";
import { Organization } from "../../types/Organization";
import { RiExternalLinkLine } from "react-icons/ri";
import { useConfig } from "../../hooks/useConfig";
import { CheckEligibilityContent } from "../Dialogs/CheckEligibilityContent";
import { RemoteData } from "../../types/remoteData";
import * as RD from "../../types/remoteData";
import { RemoteDataView } from "../RemoteDataView";
import { GenericError } from "../Feedback/GenericError";
import { Loading } from "../Feedback/Loading";
import _ from "lodash";
import { useTranslations } from "../../hooks/useTranslations";

type FormType = {
  enrollmentPeriodId: string;
  formTemplateId: string;
};
type CheckEligibilityFirstStepProps = {
  organization: Organization;
  onClose: () => void;
  onNextStep: (formTemplateId: string) => void;
};

export const CheckEligibilityFirstStep = ({
  organization,
  onClose,
  onNextStep,
}: CheckEligibilityFirstStepProps) => {
  const config = useConfig();
  const translate = useTranslations();
  const [enrollmentPeriods, setEnrollmentPeriods] = useState<
    RemoteData<Error, EnrollmentPeriod[]>
  >(RD.notAsked);
  const [formTemplates, setFormTemplates] = useState<
    RemoteData<Error, FormTemplate[]>
  >(RD.notAsked);

  const fetchFormTemplates = useCallback(
    async (enrollmentPeriodId: string): Promise<FormTemplate[] | undefined> => {
      if (organization) {
        if (_.isEmpty(enrollmentPeriodId)) {
          setFormTemplates(RD.success([]));
          return [];
        }

        setFormTemplates(RD.loading);
        let data: FormTemplate[];
        try {
          data = await fetchFormTemplatesByEnrollmentPeriod(
            organization.apply_path,
            enrollmentPeriodId
          );
        } catch (error) {
          console.error(error);
          setFormTemplates(RD.failureFromUnknown(error));
          return;
        }

        setFormTemplates(RD.success(data));
        return data;
      }

      return;
    },
    [organization]
  );

  useEffect(() => {
    const fetch = async () => {
      if (organization) {
        setEnrollmentPeriods(RD.loading);
        let enrollmentPeriodsResult: EnrollmentPeriod[];
        try {
          enrollmentPeriodsResult = await fetchEnrollmentPeriodsByOrg(
            organization.apply_path
          );
        } catch (error) {
          console.error(error);
          setEnrollmentPeriods(RD.failureFromUnknown(error));
          return;
        }

        if (enrollmentPeriodsResult.length === 0) {
          console.error("No enrollment period");
          setEnrollmentPeriods(RD.failure(new Error("no enrollment periods")));
          return;
        }

        const formTemplatesResult = await fetchFormTemplates(
          enrollmentPeriodsResult[0].id
        );
        setEnrollmentPeriods(RD.success(enrollmentPeriodsResult));

        if (
          enrollmentPeriodsResult.length === 1 &&
          formTemplatesResult?.length === 1
        ) {
          // only 1 enrollment period and 1 form template, skip step 1
          onNextStep(formTemplatesResult[0].id);
        }
      }
    };

    fetch();
  }, [organization, fetchFormTemplates, onNextStep]);

  const enrollmentPeriodOptions = useMemo(() => {
    if (!RD.isSuccess(enrollmentPeriods)) {
      return [];
    }
    return enrollmentPeriods.data.map((enrollmentPeriod) => ({
      label: enrollmentPeriod.name,
      value: enrollmentPeriod.id,
    }));
  }, [enrollmentPeriods]);

  const formTemplateOptions = useMemo(() => {
    return RD.map(formTemplates, (data) =>
      data.map((formTemplate) => ({
        label: formTemplate.name,
        value: formTemplate.id,
        description: formTemplate.description,
      }))
    );
  }, [formTemplates]);

  const onSubmit = useCallback(
    async (values: FormType) => {
      if (values.formTemplateId) {
        onNextStep(values.formTemplateId);
      }
    },
    [onNextStep]
  );

  const validate = useCallback((values: FormType) => {
    const errors: FormikErrors<FormType> = {};
    if (_.isEmpty(values.enrollmentPeriodId)) {
      errors["enrollmentPeriodId"] = "This field is required";
    }

    if (_.isEmpty(values.formTemplateId)) {
      errors["formTemplateId"] = "This field is required";
    }

    return errors;
  }, []);

  return (
    <RemoteDataView
      error={() => (
        <ModalBody>
          <GenericError />
        </ModalBody>
      )}
      loading={
        <ModalBody alignItems="center" display="flex">
          <Loading />
        </ModalBody>
      }
      remoteData={enrollmentPeriods}
    >
      {(data) => {
        return (
          <Formik<FormType>
            initialValues={{
              enrollmentPeriodId: data[0]?.id ?? "",
              formTemplateId: RD.withDefault(
                RD.map(formTemplates, (data) => data[0]?.id ?? ""),
                ""
              ),
            }}
            validate={validate}
            onSubmit={onSubmit}
          >
            {(formikProps) => {
              return (
                <Form noValidate>
                  <CheckEligibilityContent
                    onClose={onClose}
                    header={translate(
                      (t) => t.eligibility.eligibilityModal.header
                    )}
                    body={
                      <Flex direction="column" gap={4}>
                        <Text whiteSpace="pre-wrap">
                          {translate((t) => t.eligibility.stepOne.body)}
                        </Text>
                        <SingleSelect
                          name="enrollmentPeriodId"
                          label={translate(
                            (t) => t.eligibility.stepOne.enrollmentPeriod.label
                          )}
                          options={enrollmentPeriodOptions}
                          onChange={async (value) => {
                            const formTemplatesResult =
                              await fetchFormTemplates(value);
                            formikProps.setFieldValue(
                              "formTemplateId",
                              formTemplatesResult?.[0]?.id ?? ""
                            );
                          }}
                        />

                        <RadioGroup
                          key={formikProps.values.enrollmentPeriodId}
                          name="formTemplateId"
                          label={translate(
                            (t) => t.eligibility.stepOne.formTemplate.label
                          )}
                          options={formTemplateOptions}
                          emptyLabel={translate(
                            (t) => t.eligibility.eligibilityModal.empty.label
                          )}
                        />

                        {config?.eligibility?.learnMoreUrl && (
                          <Link
                            href={config.eligibility.learnMoreUrl}
                            isExternal
                            fontWeight={700}
                            color="primary.500"
                          >
                            <Flex align="center" gap={2}>
                              {translate(
                                (t) => t.eligibility.learnMoreUrl.label
                              )}{" "}
                              <RiExternalLinkLine />
                            </Flex>
                          </Link>
                        )}
                      </Flex>
                    }
                    footer={
                      <Flex direction="row" gap="2">
                        <Button
                          colorScheme="primary"
                          variant="ghost"
                          onClick={onClose}
                        >
                          {translate(
                            (t) => t.eligibility.stepOne.cancelButton.label
                          )}
                        </Button>
                        <Button
                          type="submit"
                          colorScheme="primary"
                          isDisabled={
                            !formikProps.isValid || RD.isLoading(formTemplates)
                          }
                        >
                          {translate(
                            (t) => t.eligibility.stepOne.nextButton.label
                          )}
                        </Button>
                      </Flex>
                    }
                  />
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </RemoteDataView>
  );
};
