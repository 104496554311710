import * as React from "react";
import { ChakraProvider, Flex } from "@chakra-ui/react";
import { buildTheme } from "../../services/themeBuilder";
import "@fontsource/open-sans/400.css";
import { useConfig } from "../../hooks/useConfig";
import { WelcomeEligibilityDialog } from "../Dialogs/WelcomeEligibilityDialog";
import { Loading } from "../Feedback/Loading";
import { useOrganization } from "../../hooks/useOrganization";
import { SavedFirstSchoolDialog } from "../Dialogs/SavedFirstSchoolDialog";
import { useDispatch, useSelector } from "react-redux";
import { setShowEligibilityWelcomeModal } from "../../store/modals";
import { RootState } from "../../store";
import { CheckEligibilityDialog } from "../Dialogs/CheckEligibilityDialog";

export interface Props {
  header: React.ReactElement;
  main: React.ReactElement;
}
export const TopLevelLayout = ({ header, main }: Props): React.ReactElement => {
  const config = useConfig();
  const organization = useOrganization();
  const dispatch = useDispatch();
  const {
    showEligibilityWelcomeModal,
    showFirstSavedSchoolModal,
    showCheckEligibilityModal,
  } = useSelector((state: RootState) => state.modals);

  const { savedFirstSchoolAcknowledged, eligibilityWelcomeAcknowledged } =
    useSelector((state: RootState) => state.saved);

  const theme = React.useMemo(() => {
    if (!config) return undefined;
    return buildTheme(config);
  }, [config]);

  React.useEffect(() => {
    if (config?.eligibility?.isMandatory) {
      dispatch(setShowEligibilityWelcomeModal(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  return (
    <ChakraProvider theme={theme}>
      {!config || !organization ? (
        <Loading></Loading>
      ) : (
        <Flex
          flexDir="column"
          height="calc(var(--vh, 1vh) * 100)"
          flexWrap="nowrap"
          letterSpacing="0.3px"
        >
          {header}
          {main}
          <WelcomeEligibilityDialog
            organization={organization}
            isOpen={
              showEligibilityWelcomeModal && !eligibilityWelcomeAcknowledged
            }
          />
          <SavedFirstSchoolDialog
            isOpen={showFirstSavedSchoolModal && !savedFirstSchoolAcknowledged}
          />
          <CheckEligibilityDialog isOpen={showCheckEligibilityModal} />
        </Flex>
      )}
    </ChakraProvider>
  );
};
