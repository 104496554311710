import { Box, Button, Flex, ModalBody, Text } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import {
  calculateEligibleSchoolIds,
  EligibilityForm,
  EligibilityQuestion,
  EligibilitySchool,
  fetchEligibility,
  fetchEligibilityQuestions,
  FormQuestionOption,
  getInitialValues,
  validateEligibilityForm,
} from "../../services/eligibility";
import { Form, Formik, useFormikContext } from "formik";
import { Loading } from "../Feedback/Loading";
import { SingleSelect } from "../Fields/SingleSelect";
import { AddressForm } from "../Fields/AddressForm";
import { Organization } from "../../types/Organization";
import { useDispatch, useSelector } from "react-redux";
import { setPlacementsEligibility } from "../../store/placements";
import { usePersistEligibilityAnswersForFormik } from "../../store/persistedEligibilityAnswers";
import { RootState } from "../../store";
import { RemoteData } from "../../types/remoteData";
import * as RD from "../../types/remoteData";
import { RemoteDataView } from "../RemoteDataView";
import { GenericError } from "../Feedback/GenericError";
import { CheckEligibilityContent } from "../Dialogs/CheckEligibilityContent";
import { EligibilityError } from "../Feedback/EligibilityError";
import { NoEligibleSchools } from "../Feedback/NoEligibleSchools";
import { setSortType, SortTypeEnum } from "../../store/sort";
import { useConfig } from "../../hooks/useConfig";
import { useTranslations } from "../../hooks/useTranslations";

type Props = {
  organization: Organization;
  formTemplateId: string;
  onClose: () => void;
  reset: () => void;
};
export const CheckEligibilityQuestions = (props: Props) => {
  const { organization, formTemplateId } = props;
  const [questions, setQuestions] = useState<
    RemoteData<Error, EligibilityQuestion[]>
  >(RD.notAsked);
  const language = useSelector((state: RootState) => state.lang);

  useEffect(() => {
    async function fetch() {
      setQuestions(RD.loading);
      let questions: EligibilityQuestion[];
      try {
        questions = await fetchEligibilityQuestions(
          organization.apply_path,
          formTemplateId,
          language.locale
        );
      } catch (error) {
        console.error(error);
        setQuestions(RD.failureFromUnknown(error));
        return;
      }
      if (questions.length === 0) {
        // TODO: better error handling for empty eligibility questions
        console.error("Empty eligibility questions");
        setQuestions(RD.failure(new Error("empty eligibility questions")));
        return;
      }

      setQuestions(RD.success(questions));
    }

    fetch();
  }, [formTemplateId, language.locale, organization.apply_path]);

  return (
    <RemoteDataView
      loading={
        <ModalBody display="flex" alignItems="center">
          <Loading />
        </ModalBody>
      }
      error={() => (
        <ModalBody>
          <GenericError />
        </ModalBody>
      )}
      remoteData={questions}
    >
      {(data) => {
        return <QuestionsView {...props} questions={data} />;
      }}
    </RemoteDataView>
  );
};

type QuestionsViewProps = Props & {
  questions: EligibilityQuestion[];
};
const QuestionsView: React.FC<QuestionsViewProps> = (props) => {
  const translate = useTranslations();
  const { reset, organization, formTemplateId, questions, onClose } = props;
  const dispatch = useDispatch();
  const placements = useSelector((state: RootState) => state.placements);
  const initialValues = getInitialValues(formTemplateId, questions);
  const [eligibleSchools, setEligibleSchools] = useState<
    RemoteData<Error, string[]>
  >(RD.notAsked);
  const config = useConfig();

  const validate = useCallback(
    (values: EligibilityForm) => {
      return validateEligibilityForm(values, questions);
    },
    [questions]
  );

  const onSubmit = useCallback(
    async (values: EligibilityForm) => {
      if ((!organization && !formTemplateId) || placements.status === "loading")
        return;

      setEligibleSchools(RD.loading);
      let fetchEligibilityResponse: { ineligibleSchools: EligibilitySchool[] };
      try {
        fetchEligibilityResponse = await fetchEligibility(
          organization.apply_path,
          formTemplateId,
          values
        );
      } catch (error) {
        console.error(error);
        setEligibleSchools(RD.failureFromUnknown(error));
        return;
      }

      const eligibleSchoolIds = calculateEligibleSchoolIds(
        placements.byId,
        fetchEligibilityResponse.ineligibleSchools
      );

      if (eligibleSchoolIds.length === 0) {
        setEligibleSchools(RD.success(eligibleSchoolIds));
        return;
      }

      dispatch(
        setPlacementsEligibility({
          ineligibleSchools: fetchEligibilityResponse.ineligibleSchools,
        })
      );
      onClose();
      dispatch(setSortType(SortTypeEnum.ELIGIBILITY));
    },
    [organization, formTemplateId, placements, dispatch, onClose]
  );

  return (
    <Formik<EligibilityForm>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
      validateOnMount
    >
      <RemoteDataView
        notAsked={<FormView {...props} />}
        error={() => (
          <CheckEligibilityContent
            onClose={onClose}
            header="Check eligibility"
            body={<EligibilityError />}
            footer={
              <Flex direction="row" gap="2">
                <Button variant="ghost" onClick={onClose}>
                  Cancel
                </Button>
                <Button colorScheme="primary" onClick={reset}>
                  Retry
                </Button>
              </Flex>
            }
          />
        )}
        loading={
          <ModalBody display="flex" alignItems="center">
            <Flex direction="column" alignItems="center" flexGrow="1" gap="6">
              <Loading />
              <Box>
                {translate(
                  (t) => t.eligibility.eligibilityModal.loadingMessage.label
                )}
              </Box>
            </Flex>
          </ModalBody>
        }
        remoteData={eligibleSchools}
      >
        {(eligibleSchoolIds) => {
          if (!config || !config.eligibility) {
            return <GenericError message="Missing eligibilty config" />;
          }

          if (eligibleSchoolIds.length === 0) {
            return (
              <CheckEligibilityContent
                body={<NoEligibleSchools />}
                header={translate((t) => t.eligibility.eligibilityModal.header)}
                footer={
                  <Flex minWidth="100%" justifyContent="space-between">
                    <Button variant="ghost" onClick={onClose}>
                      {translate(
                        (t) =>
                          t.eligibility.stepTwo.noEligibleCancelButton.label
                      )}
                    </Button>
                    <Button onClick={reset} colorScheme="primary">
                      {translate(
                        (t) => t.eligibility.stepTwo.noEligibleNextButton.label
                      )}
                    </Button>
                  </Flex>
                }
                onClose={onClose}
              />
            );
          }
          return null;
        }}
      </RemoteDataView>
    </Formik>
  );
};

type FormViewProps = Props & {
  questions: EligibilityQuestion[];
};
const FormView: React.FC<FormViewProps> = (props) => {
  const { formTemplateId, questions, onClose } = props;

  const buildOptions = useCallback((rawOptions: FormQuestionOption[]) => {
    return rawOptions.map((option) => ({
      label: option.label,
      value: option.id,
    }));
  }, []);

  const getFieldComponent = useCallback(
    (question: EligibilityQuestion) => {
      switch (question.type) {
        case "SingleSelect":
        case "Grades":
          return (
            <SingleSelect
              name={question.id}
              label={question.question}
              options={buildOptions(question.options)}
              isRequired={question.requirement === "Required"}
            />
          );
        case "Address":
          return (
            <AddressForm
              fieldPrefix={question.id}
              isRequired={question.requirement === "Required"}
            />
          );
        default:
          return <></>;
      }
    },
    [buildOptions]
  );

  const formik = useFormikContext<EligibilityForm>();
  usePersistEligibilityAnswersForFormik({ formTemplateId });
  const translate = useTranslations();

  return (
    <Form noValidate>
      <CheckEligibilityContent
        onClose={onClose}
        header={translate((t) => t.eligibility.eligibilityModal.header)}
        body={
          <Flex direction="column" gap={4}>
            <Text whiteSpace="pre-wrap">
              {translate((t) => t.eligibility.stepTwo.body)}
            </Text>
            {questions.map((question) => {
              return (
                <Flex key={question.id} direction="column" gap={2}>
                  {getFieldComponent(question)}
                </Flex>
              );
            })}
          </Flex>
        }
        footer={
          <Flex direction="row" gap="2">
            <Button colorScheme="primary" variant="ghost" onClick={onClose}>
              {translate((t) => t.eligibility.stepTwo.cancelButton.label)}
            </Button>
            <Button
              type="submit"
              colorScheme="primary"
              isDisabled={!formik.isValid}
            >
              {translate((t) => t.eligibility.stepTwo.nextButton.label)}
            </Button>
          </Flex>
        }
      />
    </Form>
  );
};
