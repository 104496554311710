import { Flex } from "@chakra-ui/react";
import { FreeTextInput } from "./FreeTextInput";
import { AddressAutocomplete } from "./AddressAutoComplete";
import { useCallback } from "react";
import { Address } from "../../services/address";
import { useFormikContext } from "formik";
import { EligibilityForm } from "../../services/eligibility";
import { useTranslations } from "../../hooks/useTranslations";

type Props = {
  fieldPrefix: string;
  isRequired?: boolean;
};

export const AddressForm = ({ fieldPrefix, isRequired }: Props) => {
  const formik = useFormikContext<EligibilityForm>();
  const withPrefix = useCallback(
    (name: string) => {
      if (!fieldPrefix) {
        return name;
      }

      return [fieldPrefix, name].join(".");
    },
    [fieldPrefix]
  );

  const handleAddressSelected = useCallback(
    (address: Address) => {
      formik.setValues(
        { ...formik.values, [fieldPrefix]: { ...address } },
        true // triggers validation
      );
    },
    [formik, fieldPrefix]
  );
  const translate = useTranslations();

  return (
    <Flex direction="column" gap={2}>
      <AddressAutocomplete onAddressSelected={handleAddressSelected}>
        <FreeTextInput
          isRequired={isRequired}
          name={withPrefix("streetAddress")}
          label={translate((t) => t.forms.address.addressLine1.label)}
          placeholder={translate((t) => t.forms.address.addressLine1.helpText)}
        />
      </AddressAutocomplete>
      <FreeTextInput
        name={withPrefix("streetAddressLine2")}
        label={translate((t) => t.forms.address.addressLine2.label)}
        placeholder={translate((t) => t.forms.address.addressLine2.helpText)}
      />
      <FreeTextInput
        isRequired={isRequired}
        name={withPrefix("city")}
        label={translate((t) => t.forms.address.city.label)}
        placeholder={translate((t) => t.forms.address.city.helpText)}
      />

      <Flex gap={2}>
        <FreeTextInput
          isRequired={isRequired}
          name={withPrefix("state")}
          label={translate((t) => t.forms.address.state.label)}
          placeholder={translate((t) => t.forms.address.state.helpText)}
        />
        <FreeTextInput
          isRequired={isRequired}
          name={withPrefix("zipCode")}
          label={translate((t) => t.forms.address.zipCode.label)}
          placeholder={translate((t) => t.forms.address.zipCode.helpText)}
        />
      </Flex>
    </Flex>
  );
};
