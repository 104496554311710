import { Button, ButtonProps } from "@chakra-ui/react";
import { useTranslations } from "../../hooks/useTranslations";
export interface EligibilityButtonGroupProps {
  variant: ButtonProps["variant"];
  isButtonDisabled?: boolean;
  onCancelButton: () => void;
  onContinueButton: () => void;
}
export const EligibilityButtonGroup = ({
  variant,
  isButtonDisabled,
  onCancelButton,
  onContinueButton,
}: EligibilityButtonGroupProps) => {
  const translate = useTranslations();
  const cancelText = translate(
    (t) => t.eligibility.welcomeModal.cancelButton.label
  );
  const continueText = translate(
    (t) => t.eligibility.welcomeModal.continueButton.label
  );

  return (
    <>
      <Button
        variant={variant}
        color="primary.500"
        aria-label={cancelText}
        onClick={onCancelButton}
        isDisabled={isButtonDisabled}
        _focus={{ outline: "none", boxShadow: "none" }}
      >
        {cancelText}
      </Button>

      <Button
        variant={variant}
        color="primary.500"
        aria-label={continueText}
        onClick={onContinueButton}
        isDisabled={isButtonDisabled}
        _focus={{ outline: "none", boxShadow: "none" }}
      >
        {continueText}
      </Button>
    </>
  );
};
