import { Language } from "./Language";

export interface UIConfig {
  maxNumRankable: number;
  languages?: Language[];
  boundary?: {
    sort?: boolean;
    sortText?: string;
    values: Boundary[];
  };
  primaryColor?: {
    hue: string;
    saturation: string;
    lightness: string;
  };
  secondaryColor?: {
    hue: string;
    saturation: string;
    lightness: string;
  };
  logo: ConfigurableLink & {
    imageUrl: string;
  };
  variableWords: {
    school: {
      singular: {
        label: string;
      };
      plural: {
        label: string;
      };
    };
    checkEligibility?: {
      label: string;
    };
  };

  navigation: NavigationSection;
  profile: ProfileSection;
  savedList?: SavedListSection;
  list: ListSection;
  map: MapSection;
  filters: Filter[];
  placement: PlacementMapping;
  messaging: Messaging;
  analytics?: Analytics;
  doNotTranslate?: string[];
  eligibility?: EligibilityConfig;
  translations?: Translations;
}

export type NavigationSection = {
  links: ConfigurableLink[];
};

export type ProfileSection = {
  highlighted: ContentItem[];
  keyInfo: KeyInfo[];
  tabs: Tab[];
};

export type SavedListSection = {
  disclaimer?: {
    hoverableText: string;
    header: string;
    content: string;
  };
  meter?: {
    fieldName: string;
    defaultAcceptanceRate: number;
    minimumDesired: number;
    individualScalingFactor: number;
    hoverableStates: {
      1: BaseStatic;
      2: BaseStatic;
      3: BaseStatic;
      4: BaseStatic;
      5: BaseStatic;
    };
  };
  applyNowButton?: SavedSchoolsConfigurableLink;
};

export type ListSection = {
  highlighted: ContentItem[];
  disclaimer?: {
    label: string;
    value: string;
  };
};

export type MapSection = {
  popover: {
    highlighted: ContentItem[];
  };

  listPopover: {
    highlighted: ContentItem[];
  };

  miniPlacementCard: {
    highlighted: ContentItem[];
  };
};

export type Filter = MultipleFilter | DistanceFilter;

export type PlacementMapping = {
  idFieldName: string;
  nameFieldName: string;
  latFieldName: string;
  lngFieldName: string;
  boundaryFieldName?: { [boundaryType: string]: string };
};

export type ContentItem = ContentItemLink | BaseStatic | BaseField;

type BaseField = {
  contentType?: "field";
  fieldName: string;
  label?: string;
  helpText?: string;
  displayTransformation?: "range";
};

type BaseStatic = {
  contentType: "static";
  label?: string;
  value: string;
  helpText?: string;
};

export type Website = "website";
type BaseLink = {
  type: Website;
  opensNewTab?: boolean;
  helpText?: string;
};
export type ContentItemLink = (BaseField & BaseLink) | (BaseStatic & BaseLink);

export type ConfigurableLink = {
  label: string;
  href: string;
  opensNewTab?: boolean;
};

export type SavedSchoolsConfigurableLink = ConfigurableLink & {
  forwardSavedSchools?: boolean;
};

export type Tab = {
  label: string;
  items: ContentItem[];
};

type BaseKeyInfo = { type: KeyInfoTypes };

export type KeyInfo =
  | (BaseField & BaseKeyInfo)
  | (BaseStatic & BaseKeyInfo)
  | ContentItemLink;

export type KeyInfoTypes =
  | "location"
  | "contact"
  | "principal"
  | "hours"
  | "bus"
  | "group"
  | "paper"
  | "building"
  | "other"
  | Website
  | "award"
  | "pushpin";

export type BaseFilter = {
  label: string;
  fieldName: string;
};

export type MultipleFilter = BaseFilter & {
  type: "multiple";
  options: string[];
};

const DISTANCE_FIELD_NAME = "distance";
export const RESERVED_FIELD_NAMES = [DISTANCE_FIELD_NAME];

export type DistanceFilter = BaseFilter & {
  type: "distance";
  fieldName: typeof DISTANCE_FIELD_NAME;
  maxMiles: number;
  step?: number;
};

export type Messaging = {
  sms: {
    from: string;
    body: string;
    applyNow: string;
  };
  email: {
    from: string;
    subject: string;
    body: string;
    applyNow: string;
  };
};

type Analytics = {
  googleMeasurementId?: string;
};

type Boundary = {
  url: string;
  config: BoundaryConfig;
};
export type BoundaryConfig = {
  boundaryType: string;
  idProperty: string;
  showAllBoundaries: boolean;
  showAddressBoundary: boolean;
  showSelectedPlacementBoundary: boolean;
  tagLabel?: string;
  showMapLabel?: boolean;
  style?: {
    [id: string]: BoundaryStyle;
  };
};
export type BoundaryStyle = {
  color: string;
};

export interface Translations {
  savedFirstSchool: {
    header: string;
    body: string;
    closeButton: { label: string };
  };
  forms: {
    dropdown: {
      helpText: string;
    };
    address: {
      addressLine1: { label: string; helpText: string };
      addressLine2: { label: string; helpText: string };
      zipCode: { label: string; helpText: string };
      city: { label: string; helpText: string };
      state: { label: string; helpText: string };
    };
  };
  eligibility: {
    filter: {
      header: string;
      body: string;
    };
    error: {
      header: string;
      body: string;
    };
    notEligibleForAny: {
      header: string;
      body: string;
    };
    learnMoreUrl: { label: string };
    alert: { avlBtn: { label: string }; label: string };
    nudge: { avlBtn: { label: string }; label: string };
    stepOne: {
      body: string;
      enrollmentPeriod: { label: string };
      formTemplate: { label: string };
      nextButton: { label: string };
      cancelButton: { label: string };
    };
    stepTwo: {
      body: string;
      noEligibleCancelButton: { label: string };
      noEligibleNextButton: { label: string };
      nextButton: { label: string };
      cancelButton: { label: string };
    };
    eligibilityModal: {
      header: string;
      empty: { label: string };
      loadingMessage: { label: string };
    };
    welcomeModal: {
      header: string;
      changeEligibility: { label: string };
      body: string;
      acknowledgement: { label: string };
      cancelButton: { label: string };
      continueButton: { label: string };
    };
  };
}

export interface EligibilityConfig {
  isEnabled: boolean;
  isMandatory: boolean;
  learnMoreUrl: string;
  placementEligibility: PlacementEligibility;

  welcomeModal: {
    acknowledgementEnabled: boolean;

    // TODO: for backward compatibility, remove this after translations is released
    titleText: string;
    bodyText: string;
    acknowledgementText: string;
    cancelButtonText: string;
    continueButtonText: string;
  };
  // TODO: for backward compatibility, remove this after translations is released
  error: {
    header: string;
    body: string;
  };
}

/**
 * Allow admin to disable eligibility check for any given placement via data
 */
export interface PlacementEligibility {
  // name of the column in the data source, e.g: "show_eligibility"
  fieldName: string;

  // value in the data column to indicate that eligibility is disabled,
  // case insensitive, e.g.: "No"
  disabledValue: string;
}

export function isEligibilityNotApplicable(
  placementRaw: any,
  eligibilityConfig: EligibilityConfig
): boolean {
  const placementEligibility = eligibilityConfig.placementEligibility;
  if (!eligibilityConfig.isEnabled) {
    // if eligibility is disabled on org level, it should be disabled on all placement level
    return false;
  }

  if (placementEligibility === undefined) {
    // if placementEligibility is not configured for this organization,
    // enable it for all school by default
    return true;
  }

  const value: unknown = placementRaw[placementEligibility.fieldName];
  if (value === undefined || typeof value !== "string") {
    // eligibility data is missing for this placement
    // enable it for for this placement by default
    return true;
  }

  // do case insensitive string comparison using "en" locale to avoid
  return (
    value.toLocaleLowerCase("en") ===
    placementEligibility.disabledValue.toLocaleLowerCase("en")
  );
}
