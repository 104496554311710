import { Flex, BoxProps, CloseButton, Button, Spacer } from "@chakra-ui/react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { FilterSliceState, clearFilters } from "../../store/filter";
import {
  DistanceFilter as DistanceFilterType,
  Filter as FilterType,
  MultipleFilter,
} from "../../types/UIConfig";
import { DistanceFilter } from "./components/DistanceFilter";
import { MultipleSelectFilter } from "./components/MultipleSelectFilter";
import { CheckEligibility } from "./components/CheckEligibility";
import { setShowCheckEligibilityModal } from "../../store/modals";
import { useTranslations } from "../../hooks/useTranslations";

type Props = {
  onClose: () => void;
} & BoxProps;

function getSelectedValues(
  filter: MultipleFilter,
  filterSlice: FilterSliceState
): string[] {
  if (filter.fieldName in filterSlice.byFieldName) {
    const filterState = filterSlice.byFieldName[filter.fieldName];
    if (filterState.type === "multiple") return filterState.selected;
  }

  return [];
}

function getSelectedDistanceValue(
  filter: DistanceFilterType,
  filterSlice: FilterSliceState
): number | undefined {
  if (filter.fieldName in filterSlice.byFieldName) {
    const filterState = filterSlice.byFieldName[filter.fieldName];
    if (filterState.type === "distance") return filterState.selected;
  }
}

export const Filter = ({
  onClose,
  ...props
}: Props): React.ReactElement | null => {
  const config = useSelector((state: RootState) => state.config);
  const filterSlice = useSelector((state: RootState) => state.filter);
  const address = useSelector((state: RootState) => state.address);
  const placements = useSelector((state: RootState) => state.placements);
  const dispatch = useDispatch();
  const translate = useTranslations();

  if (config.state === "loading" || placements.status === "loading")
    return null;

  const eligibilityButtonText = placements.eligibilityEnabled
    ? translate((t) => t.eligibility.welcomeModal.changeEligibility.label)
    : translate((t) => t.eligibility.welcomeModal.continueButton.label);

  const handleClearAll = () => {
    dispatch(clearFilters());
  };

  return (
    <Flex
      {...props}
      width="100%"
      position="relative"
      overflow="auto"
      height="100%"
      direction="column"
      background="gray.50"
    >
      <Flex align="center" marginTop={2}>
        <Button
          onClick={handleClearAll}
          variant="unstyled"
          size="sm"
          fontSize="sm"
          fontWeight="normal"
          marginX={6}
        >
          <FormattedMessage id="CLEAR_FILTER_LABEL" />
        </Button>
        <Spacer />
        <CloseButton onClick={() => onClose()} marginX={2} size="md" />
      </Flex>
      <Flex direction="column" gap={4} padding={6} zIndex={props.zIndex}>
        {config.values.eligibility?.isEnabled && (
          <CheckEligibility
            buttonText={eligibilityButtonText}
            onCheckEligibility={() => {
              dispatch(setShowCheckEligibilityModal(true));
              onClose();
            }}
          />
        )}
        {config.values.filters?.map((filter: FilterType) => {
          switch (filter.type) {
            case "multiple":
              return (
                <MultipleSelectFilter
                  key={filter.fieldName}
                  filter={filter}
                  selectedValues={getSelectedValues(filter, filterSlice)}
                />
              );
            case "distance":
              return (
                <DistanceFilter
                  key={filter.fieldName}
                  filter={filter}
                  selectedValue={getSelectedDistanceValue(filter, filterSlice)}
                  isDisabled={address.status !== "finished"}
                  disabledReason={
                    <FormattedMessage id="DISTANCE_DISABLED_REASON" />
                  }
                />
              );
            default:
              return <></>;
          }
        })}
      </Flex>
    </Flex>
  );
};
