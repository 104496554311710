import { ModalBody, Flex, ModalFooter } from "@chakra-ui/react";
import React from "react";

type Props = {
  header: string;
  body: React.ReactNode;
  footer: React.ReactNode;
  onClose: () => void;
};
export const CheckEligibilityContent: React.FC<Props> = ({
  header,
  body,
  footer,
  onClose,
}) => {
  return (
    <>
      <ModalBody as={Flex} direction="column" p={6} maxH={400} overflow="auto">
        {body}
      </ModalBody>
      <ModalFooter gap={2} borderTop="1px solid" borderColor="gray.200">
        {footer}
      </ModalFooter>
    </>
  );
};
