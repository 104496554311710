import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { CheckEligibilityLink } from "../Buttons/CheckEligibilityLink";
import { Text } from "@chakra-ui/react";
import { InfoAlert } from "./InfoAlert";
import {
  setShowCheckEligibilityModal,
  setShowFirstSavedSchoolModal,
} from "../../store/modals";
import React from "react";

type LinkConfig = {
  avlBtn: {
    label: string;
  };
  label: string;
};

export type Props = {
  children: ({
    checkEligibilityLink,
  }: {
    checkEligibilityLink: (
      linkConfig: LinkConfig | undefined
    ) => React.ReactNode;
  }) => React.ReactNode;
};

export const EligibilityInfoAlert: React.FC<Props> = ({ children }: Props) => {
  const config = useSelector((state: RootState) => state.config);
  const placements = useSelector((state: RootState) => state.placements);
  const dispatch = useDispatch();

  if (config.state === "loading") return <></>;
  if (placements.status === "loading") return <></>;
  if (!config.values.eligibility?.isEnabled) return <></>;

  const checkEligibilityLink = (linkConfig: LinkConfig | undefined) => {
    if (!linkConfig) return null;

    const nudgeLabel = linkConfig.label;
    const buttonLabel = linkConfig.avlBtn.label;
    const [head, tail] = nudgeLabel.split("{{avlBtn}}");
    return (
      <>
        {head}
        <CheckEligibilityLink
          onClick={() => {
            dispatch(setShowFirstSavedSchoolModal(false));
            dispatch(setShowCheckEligibilityModal(true));
          }}
          continueButtonText={buttonLabel}
        />
        {tail}
      </>
    );
  };

  return !placements.eligibilityEnabled ? (
    <InfoAlert children={<Text>{children({ checkEligibilityLink })}</Text>} />
  ) : (
    <></>
  );
};
