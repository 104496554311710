import { UIConfig } from "../types/UIConfig";
import { isEligible, Placement } from "../types/Placement";
import { SortTypeEnum } from "../store/sort";
import {
  DistanceFieldState,
  FieldState,
  MultipleFieldState,
} from "../store/filter";
import { DistanceById } from "../store/distance";
import { BoundaryTagsByPlacementId } from "../hooks/useBoundary";

export const filterAndSort = (
  placementsParam: Placement[],
  config: UIConfig,
  filters: FieldState[],
  sort: SortTypeEnum,
  distanceByPlacementId: DistanceById,
  bounds: google.maps.LatLngBoundsLiteral | undefined,
  boundaryTagsMap: BoundaryTagsByPlacementId
): Placement[] => {
  let placements = placementsParam;

  placements = selectPlacementsInMapBounds(
    placements,
    config,
    bounds
      ? new google.maps.LatLngBounds(
          { lat: bounds.south, lng: bounds.west },
          { lat: bounds.north, lng: bounds.east }
        )
      : undefined
  );

  placements = selectPlacementsMatchingFilters(
    placements,
    distanceByPlacementId,
    filters
  );

  placements = sortPlacements(
    placements,
    sort,
    distanceByPlacementId,
    boundaryTagsMap
  );

  return placements;
};

const sortAlphabetical = (placements: Placement[]) =>
  placements.sort((a, b) => {
    const aName = a.name;
    const bName = b.name;

    if (!aName || !bName) return 0;

    return aName < bName ? -1 : 1;
  });

export const sortPlacements = (
  placements: Placement[],
  sortType: SortTypeEnum,
  distanceByPlacementId: DistanceById,
  boundaryTagsMap: BoundaryTagsByPlacementId
): Placement[] => {
  switch (sortType) {
    case SortTypeEnum.ALPHABETICAL: {
      return sortAlphabetical(placements);
    }
    case SortTypeEnum.DISTANCE: {
      return placements.sort((a, b) => {
        return distanceByPlacementId[a.id] < distanceByPlacementId[b.id]
          ? -1
          : 1;
      });
    }
    case SortTypeEnum.BOUNDARY: {
      return placements.sort((a, b) => {
        const boundaryTagsA = boundaryTagsMap[a.id] ?? [];
        const boundaryTagsB = boundaryTagsMap[b.id] ?? [];

        const tagsDiff = boundaryTagsB.length - boundaryTagsA.length;
        if (tagsDiff !== 0) {
          return tagsDiff;
        }

        return distanceByPlacementId[a.id] < distanceByPlacementId[b.id]
          ? -1
          : 1;
      });
    }
    case SortTypeEnum.ELIGIBILITY: {
      const eligiblePlacements = sortAlphabetical(
        placements.filter((x) => isEligible(x))
      );
      const additionalPlacements = sortAlphabetical(
        placements.filter((x) => !isEligible(x))
      );

      return [...eligiblePlacements, ...additionalPlacements];
    }
    default: {
      const _exhaustiveCheck: never = sortType;
      return _exhaustiveCheck;
    }
  }
};

export const selectPlacementsMatchingFilters = (
  placements: Placement[],
  distanceByPlacementId: DistanceById,
  filters: FieldState[]
): Placement[] => {
  let results = [...placements];

  filters.forEach((filter, index) => {
    // eslint-reason Already guaranteed to return boolean by TypeScript.
    // eslint-disable-next-line array-callback-return
    results = results.filter((placement): boolean => {
      switch (filter.type) {
        case "multiple":
          return filterByMultiple(placement, filter);
        case "distance":
          return filterByDistance(placement, distanceByPlacementId, filter);
      }
    });
  });

  return results;
};

const filterByMultiple = (
  placement: Placement,
  filter: MultipleFieldState
): boolean => {
  const selected = Array.isArray(filter.selected)
    ? filter.selected
    : [filter.selected];

  if (selected.length > 0) {
    const fieldValue = placement.details[filter.fieldName];
    let fieldValues = Array.isArray(fieldValue) ? fieldValue : [fieldValue];
    return overlaps(selected, fieldValues);
  }

  return true;
};

const filterByDistance = (
  placement: Placement,
  distanceByPlacementId: DistanceById,
  filter: DistanceFieldState
): boolean => {
  const distance = distanceByPlacementId[placement.id];
  if (distance === undefined) {
    return true;
  }

  return distance <= filter.selected;
};

const overlaps = (
  selected: string[],
  fieldContents: (string | number)[]
): boolean => {
  return (
    selected.find((selectedItem) => {
      return fieldContents.find((content) => {
        return String(content) === selectedItem;
      });
    }) !== undefined
  );
};

export const selectPlacementsInMapBounds = (
  placements: Placement[],
  config: UIConfig,
  bounds: google.maps.LatLngBounds | undefined
): Placement[] => {
  return placements.filter((placement) => {
    let isMatch = true;

    const { lat, lng } = placement;
    if (bounds) {
      if (!isWithinBounds(Number(lat), Number(lng), bounds)) {
        isMatch = false;
      }
    }

    return isMatch;
  });
};

const isWithinBounds = (
  lat: number,
  lng: number,
  bounds: google.maps.LatLngBounds
): boolean => {
  return bounds.contains(new google.maps.LatLng(lat, lng));
};
