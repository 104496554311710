import { Flex, Image, Text } from "@chakra-ui/react";
import { useTranslations } from "../../hooks/useTranslations";
import eligibilityError from "../../images/eligibility-error.svg";

export function EligibilityError() {
  const translate = useTranslations();
  const header = translate((t) => t.eligibility.error?.header);
  const body = translate((t) => t.eligibility.error?.body);
  return (
    <Flex
      top="0"
      left="0"
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      direction="column"
      gap={2}
      mb={4}
    >
      <Image width={140} src={eligibilityError} />
      <Text fontWeight="bold">{header}</Text>
      <Text textAlign="center">{body}</Text>
    </Flex>
  );
}
