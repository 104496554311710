import {
  Box,
  Text,
  Image,
  Flex,
  Icon,
  IconButton,
  Tag,
  TagLabel,
  TagLeftIcon,
} from "@chakra-ui/react";
import React from "react";
import { DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import {
  RiIndeterminateCircleLine,
  RiMenuLine,
  RiStarLine,
} from "react-icons/ri";
import { useColors } from "../hooks/useColors";
import { usePlacementData } from "../hooks/usePlacementData";
import { removeSaved } from "../store/saved";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import noSchoolImg from "../images/no-photo-tn.jpg";
import { thumbnailUrl } from "../services/urlBuilder";
import { useIntl } from "react-intl";
import { usePanelDisclosure } from "../hooks/usePanelDislosure";
import { useFocusStyle } from "../hooks/useFocusStyle";
import { useOrganization } from "../hooks/useOrganization";
import { isEligible } from "../types/Placement";

function updateTransform(
  provided: DraggableProvided,
  transform: string | undefined
): DraggableProvided {
  const style = provided.draggableProps.style
    ? { ...provided.draggableProps.style, transform }
    : provided.draggableProps.style;
  const draggableProps = {
    ...provided.draggableProps,
    style,
  };
  const updated = {
    ...provided,
    draggableProps,
  };

  return updated;
}

function updateProvided(
  provided: DraggableProvided,
  index: number,
  draggedIndex: number | undefined,
  snapshot: DraggableStateSnapshot,
  outerHeight: number
): DraggableProvided {
  if (!snapshot.isDragging) {
    if (draggedIndex !== undefined) {
      if (draggedIndex < index) {
        // for card below the dragged card, we need to adjust the translate position based on the outer height of the card.
        const transform = provided.draggableProps.style?.transform
          ? undefined
          : `translate(0px, -${outerHeight}px)`;
        return updateTransform(provided, transform);
      }
    } else {
      // this to handle a period of time where dragging has started, but we don't have draggedIndex value yet.
      // in this case, don't do transformation so we don't see the element jumping around.
      return updateTransform(provided, undefined);
    }
  }

  return provided;
}

function calculateOuterHeight(el: HTMLDivElement) {
  var height = el.offsetHeight;
  var style = getComputedStyle(el);

  height += parseInt(style.marginTop) + parseInt(style.marginBottom);
  return height;
}

type SavedPlacementProps = {
  id: string;
  index: number;
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  draggedIndex: number | undefined;
};
export const SavedPlacementCard = (
  props: SavedPlacementProps
): React.ReactElement | null => {
  const { id, index, provided, draggedIndex, snapshot } = props;
  const config = useSelector((state: RootState) => state.config);
  const organization = useOrganization();
  const ref = React.useRef<HTMLDivElement>(null);
  const [outerHeight, setOuterHeight] = React.useState<number>(0);
  const intl = useIntl();
  const dispatch = useDispatch();
  const { openPlacementProfile } = usePanelDisclosure();
  const focusStyle = useFocusStyle({ position: "offset" });
  const focusStyleDeleteButton = useFocusStyle({ position: "inside-offset" });

  React.useEffect(() => {
    if (!ref.current) {
      return;
    }
    setOuterHeight(calculateOuterHeight(ref.current));
  }, [snapshot]);

  const { placement, isElibilityEnabled } = usePlacementData(id);

  const { primary } = useColors();

  if (config.state !== "finished") return null;

  const handleRemove = (id: string) => () => {
    dispatch(removeSaved(id));
  };

  let updatedProvided = updateProvided(
    provided,
    index,
    draggedIndex,
    snapshot,
    outerHeight
  );
  return (
    <Flex key={id} align="center" marginBottom={2} ref={ref}>
      <Text
        fontSize="14"
        color="gray.700"
        fontWeight={600}
        margin={3}
        align="center"
        _after={{
          content: "'00'",
          display: "block",
          height: 0,
          visibility: "hidden",
        }}
      >
        {index + 1}
      </Text>
      <Box width="100%" height="60px">
        <Flex
          height="60px"
          backgroundColor="white"
          borderRadius="4px"
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.15)"
          marginBottom={2}
          align="center"
          flexGrow={1}
          _hover={{ backgroundColor: "gray.100" }}
          onClick={() => openPlacementProfile(id)}
          ref={updatedProvided.innerRef}
          sx={focusStyle}
          {...updatedProvided.draggableProps}
          {...updatedProvided.dragHandleProps}
        >
          <Flex
            backgroundColor={primary}
            height="100%"
            minWidth="24px"
            borderRadius="4px 0 0 4px"
            align="center"
            justify="center"
          >
            <Icon as={RiMenuLine} height="14px" color="white" />
          </Flex>

          <Flex direction="row" flexGrow={1} alignItems="center">
            <Text fontSize="sm" paddingX={4}>
              {placement?.name}
            </Text>
            {isElibilityEnabled &&
              placement !== undefined &&
              isEligible(placement) && (
                <Tag
                  flexShrink={0}
                  flexGrow={0}
                  size="sm"
                  key={placement?.id}
                  variant="outline"
                  colorScheme="green"
                  marginRight={3}
                >
                  <TagLeftIcon boxSize="12px" as={RiStarLine} />
                  <TagLabel>Eligible</TagLabel>
                </Tag>
              )}
          </Flex>
          <Image
            src={thumbnailUrl(organization?.path || "", id)}
            fallbackSrc={noSchoolImg}
            alt={placement?.name}
            height="100%"
            width="72px"
            minWidth="72px"
            borderRadius="0 4px 4px 0"
            objectFit="cover"
          />
        </Flex>
      </Box>
      <IconButton
        title={intl.formatMessage(
          { id: "REMOVE_PLACEMENT_BUTTON_LABEL" },
          {
            school:
              config.values.variableWords.school.singular.label.toLowerCase(),
          }
        )}
        aria-label={intl.formatMessage(
          { id: "REMOVE_PLACEMENT_BUTTON_LABEL" },
          {
            school:
              config.values.variableWords.school.singular.label.toLowerCase(),
          }
        )}
        icon={<Icon as={RiIndeterminateCircleLine} />}
        onClick={handleRemove(id)}
        variant="unstyled"
        size="md"
        fontSize="lg"
        color="gray.700"
        sx={{
          ...focusStyleDeleteButton,
          "@media(hover)": {
            ":hover": { color: "red.500" },
          },
        }}
        cursor="pointer"
      />
    </Flex>
  );
};
