import {
  CloseButton,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
} from "@chakra-ui/react";
import { useOrganization } from "../../hooks/useOrganization";
import { CheckEligibilityFirstStep } from "../Forms/CheckEligibilityFirstStep";
import { useCallback, useState } from "react";
import { CheckEligibilityQuestions } from "../Forms/CheckEligibilityQuestions";
import { useConfig } from "../../hooks/useConfig";
import { useDispatch } from "react-redux";
import { setShowCheckEligibilityModal } from "../../store/modals";
import { useTranslations } from "../../hooks/useTranslations";

type CheckEligibilityState = { step: 1 } | { step: 2; formTemplateId: string };
type CheckEligibilityDialogProps = {
  isOpen: boolean;
};
export const CheckEligibilityDialog = ({
  isOpen,
}: CheckEligibilityDialogProps) => {
  const config = useConfig();
  const translate = useTranslations();
  const dispatch = useDispatch();
  const organization = useOrganization();
  const [state, setState] = useState<CheckEligibilityState>({ step: 1 });

  const reset = useCallback(() => {
    setState({ step: 1 });
  }, []);

  const close = useCallback(() => {
    reset();
    dispatch(setShowCheckEligibilityModal(false));
  }, [reset, dispatch]);

  if (!organization) return <></>;

  let content: React.ReactNode;
  let header: string = translate((t) => t.eligibility?.eligibilityModal.header);

  switch (state.step) {
    case 1:
      content = (
        <CheckEligibilityFirstStep
          onClose={close}
          onNextStep={(formTemplateId: string) =>
            setState({ step: 2, formTemplateId })
          }
          organization={organization}
        />
      );
      break;
    case 2:
      content = (
        <CheckEligibilityQuestions
          organization={organization}
          formTemplateId={state.formTemplateId}
          onClose={close}
          reset={reset}
        />
      );
      break;
    default:
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _exhaustiveCheck: never = state;
      throw new Error(`Unhandled state: ${state}`);
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={close}
      isCentered
      closeOnOverlayClick={config?.eligibility?.isMandatory ? false : true}
    >
      <ModalOverlay />

      <ModalContent minHeight={350} m={4}>
        <ModalHeader
          fontSize="lg"
          fontWeight="bold"
          borderBottom="1px solid"
          borderColor="gray.200"
          display="flex"
          alignItems="center"
        >
          {header}
          <Spacer />
          <CloseButton onClick={close} />
        </ModalHeader>
        {content}
      </ModalContent>
    </Modal>
  );
};
