import {
  Checkbox,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { EligibilityButtonGroup } from "../Buttons/EligibilityButtonGroup";
import { Organization } from "../../types/Organization";
import { useDispatch } from "react-redux";
import {
  setShowEligibilityWelcomeModal,
  setShowCheckEligibilityModal,
} from "../../store/modals";
import { setEligibilityWelcomeAcknowledged } from "../../store/saved";
import { useTranslations } from "../../hooks/useTranslations";
import { useConfig } from "../../hooks/useConfig";

type WelcomeEligibilityDialogProps = {
  organization: Organization;
  isOpen: boolean;
};
export const WelcomeEligibilityDialog = ({
  organization,
  isOpen,
}: WelcomeEligibilityDialogProps) => {
  const dispatch = useDispatch();
  const config = useConfig();
  const translate = useTranslations();

  const [isAcknowledged, setIsAcknowledged] = useState<boolean>(
    config?.eligibility?.welcomeModal.acknowledgementEnabled ?? false
  );

  const defaultBodyText = `Welcome to Explore, the best way to discover ${organization.title}’s Schools, calculate travel times, and learn about their educational programming!\n\nFind out what a student’s options by checking eligibility before applying. Save schools to create a list if you want to apply to more than one.`;

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {}}
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent m={4}>
        <ModalHeader fontSize="lg" fontWeight="bold">
          {translate((t) => t.eligibility.welcomeModal.header)}
        </ModalHeader>
        <ModalBody as={Flex} direction="column" gap={4}>
          <Text whiteSpace="pre-wrap">
            {
              (translate((t) => t.eligibility.welcomeModal.body),
              defaultBodyText)
            }
          </Text>

          {config?.eligibility?.welcomeModal.acknowledgementEnabled ===
            true && (
            <Checkbox
              paddingTop="0.5rem"
              paddingBottom="0.5rem"
              onChange={(event) => {
                setIsAcknowledged(event.target.checked);
              }}
            >
              {translate(
                (t) => t.eligibility.welcomeModal.acknowledgement.label
              )}
            </Checkbox>
          )}
        </ModalBody>
        <ModalFooter>
          <EligibilityButtonGroup
            variant="ghost"
            onCancelButton={() => {
              dispatch(setEligibilityWelcomeAcknowledged(true));
              dispatch(setShowEligibilityWelcomeModal(false));
            }}
            onContinueButton={() => {
              dispatch(setEligibilityWelcomeAcknowledged(true));
              dispatch(setShowEligibilityWelcomeModal(false));
              dispatch(setShowCheckEligibilityModal(true));
            }}
            isButtonDisabled={!isAcknowledged}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
