import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { CheckEligibilityButton } from "./CheckEligibilityButton";
import { useTranslations } from "../../../hooks/useTranslations";

export type CheckEligibilityProps = {
  buttonText: string;
  onCheckEligibility: () => void;
};
export const CheckEligibility = ({
  buttonText,
  onCheckEligibility,
}: CheckEligibilityProps): React.ReactElement => {
  const translate = useTranslations();
  return (
    <Flex direction="column" gap={4}>
      <Flex direction="column">
        <Text>{translate((t) => t.eligibility.filter.header)}</Text>
        <Text fontSize="sm" color="gray.700" whiteSpace="pre-wrap">
          {translate((t) => t.eligibility.filter.body)}
        </Text>
      </Flex>

      <Flex>
        <CheckEligibilityButton
          variant="outline"
          buttonText={buttonText}
          onClick={onCheckEligibility}
        />
      </Flex>
    </Flex>
  );
};
